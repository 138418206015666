<template>
  <section class="pb-2">
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary font-weight-bold">
            Multipubblicazione
          </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>

            <b-breadcrumb-item active> Multipubblicazione </b-breadcrumb-item>
            <b-breadcrumb-item> Seleziona auto </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </template>

      <b-row class="py-2">
        <b-col md="3">
          <span class="text-primary-light font-weight-bold">
            Gestionale Auto:
          </span>
          <span class="text-primary-light"> 25 / 40 </span>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="9"> </b-col>

        <b-col md="3">
          <div class="d-flex align-items-center mb-2">
            <span class="mr-1">Cerca</span>
            <b-form-input type="text" v-model="filter" size="md" debounce="500"/>
          </div>
        </b-col>
      </b-row>

      <b-table
        :fields="fields"
        :items="carProvider"
        :filter="filter"
        :busy="loading"
        :current-page="currentPage"
        striped
        show-empty
        responsive
        @sort-table="sortTable"
      >
        <template #table-busy>
          <div class="text-center text-primary-light my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Caricamento dati delle auto...</strong>
          </div>
        </template>
        <template #cell(id)="data">
          {{ data.item.id_auto }}
        </template>

        <template #cell(gst.auto)="data">
            <b-form-checkbox :checked="data.item.multipublish" />
        </template>

        <template #cell(foto)="data">
            <b-img :src="data.item.foto1_ex"></b-img>
        </template>

        <template #cell(anno)="data">
          {{ data.item.immatricolazione_anno }}
        </template>

        <template #cell(km)="data">
          {{ data.item.chilometraggio }}
        </template>

        <template #cell(opzioni)>
          <feather-icon
            icon="ListIcon"
            size="25"
            class="cursor-pointer"
          ></feather-icon>
        </template>
      </b-table>

      <b-pagination
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="vehicles.count"
        align="right"
        size="sm"
        class="my-0"
      />
    </b-card>

    <!-- Modali -->
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BTable,
  BFormCheckbox,
  BFormInput,
  BImg,
  BSpinner,
  BPagination
} from "bootstrap-vue";

import { Requests } from "@/api";

export default {
  components: {
    BRow,
    BCol,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BTable,
    BFormCheckbox,
    BFormInput,
    BImg,
    BSpinner,
    BPagination
  },

  data: () => ({
    fields: [
      "id",
      "gst.auto",
      "foto",
      "marca",
      "modello",
      "versione",
      "anno",
      "km",
      "targa",
      "telaio",
      "prezzo_pubblico",
      "prezzo_rivenditore",
      { key: "opzioni" },
    ],
    vehicles: [],
    filter: null,
    sortBy: 'id_auto',
    sortDesc: false,

    //Helpers

    loading: false,
    currentPage: 1,
    perPage: 15,
  }),

  methods: {
    async getAutoOnSale(page) {
      this.loading = true;

      page = page ?? this.currentPage;

      let request_data = new FormData();

      request_data.append("offset", (page - 1) * this.perPage);
      request_data.append("limit", this.perPage);
      request_data.append("filter", this.filter);
      request_data.append("sortBy", this.sortBy);
      request_data.append("sortDesc", this.sortDesc);

      try {
        const response = await Requests.getAutoOnSale();
        this.vehicles = response;
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },

    async carProvider() {
      await this.getAutoOnSale();
      return this.vehicles.data;
    },

    async sortTable(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
    },
  },
};
</script>

<style scoped>
.offset-translate {
  transform: translate(-5px, 3px);
}
</style>
